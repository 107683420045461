import type { LoadingProps } from "@components/loading";
import { styled } from "@infinitaslearning/pixel-design-system";

export const LoadingContainer = styled("div")<LoadingProps>`
  grid-column: 1/13;
  height: 100%;
  width: 100%;
  padding: ${({ theme }) => theme.spacing(1)};
  background-color: ${({ backgroundColor }) => backgroundColor};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const LoadingImageContainer = styled("div")<LoadingProps>`
  display: block;
  width: ${({ size }) => (size ? (size === "small" ? "100px" : "180px") : "240px")};
  height: ${({ size }) => (size ? (size === "small" ? "100px" : "180px") : "240px")};
  margin: auto;

  @keyframes heartbeat {
    0% {
      transform: scale(1);
    }
    20% {
      transform: scale(1.1);
    }
  }

  animation: heartbeat 3s infinite;
`;
