import type { NextPageWithLayout } from "@pages/types";
import { PupilPlatform } from "src/styles/global-styling/global.styles";

type LoggedOutViewProps = {
  Component: NextPageWithLayout;
  // biome-ignore lint/suspicious/noExplicitAny: We don't know the type of the object
  pageProps: any;
};

export const LoggedOutView = ({ Component, pageProps }: LoggedOutViewProps) => {
  const getLayout = Component.getLayout ?? ((page) => page);

  return <PupilPlatform>{getLayout(<Component {...pageProps} />)}</PupilPlatform>;
};
