import { useBrand } from "@utils/use-brand";
import type React from "react";
import { useEffect, useState } from "react";

import { LoadingContainer, LoadingImageContainer } from "./loading.styles";

type LoadingSize = "small" | "large";

export type LoadingProps = {
  backgroundColor?: string;
  size?: LoadingSize;
  disableDelay?: boolean;
};

export const Loading: React.FC<LoadingProps> = ({
  backgroundColor = "",
  size,
  disableDelay = false,
}) => {
  const [showLoading, setShowLoading] = useState(disableDelay);
  const {
    components: { LoadingImage },
  } = useBrand();

  useEffect(() => {
    const timeOut = setTimeout(() => {
      setShowLoading(true);
    }, 500);

    return () => clearTimeout(timeOut);
  });

  if (!showLoading) return null;

  return (
    <LoadingContainer data-testid="loading-screen" backgroundColor={backgroundColor}>
      <LoadingImageContainer size={size}>
        <LoadingImage />
      </LoadingImageContainer>
    </LoadingContainer>
  );
};
