import type { LogoProps } from "@utils/brand-components";
import { type FC, memo } from "react";

// We should not use localization because language can change but is not necessarily based on opCo
const LOGO_LABEL = "Scoodle";

const ScoodleLogo: FC<LogoProps> = ({ variant, width, height, dataTestId }) => {
  if (variant === "circle")
    return (
      <svg
        width={width}
        height={height}
        data-testid={dataTestId}
        viewBox="0 0 53 53"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>{LOGO_LABEL}</title>
        <g filter="url(#filter0_d_546_53058)">
          <circle cx="25" cy="25" r="25" fill="#168482" />
          <circle cx="25" cy="25" r="24.75" stroke="black" strokeOpacity="0.1" strokeWidth="0.5" />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9 21.4351L11.6601 33.2538L24.5165 11L9 21.4351Z"
          fill="white"
          fillOpacity="0.75"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.3898 29.4817L24.5165 39L34.6336 29.4817L24.5165 11L14.3898 29.4817Z"
          fill="white"
          fillOpacity="0.3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.5165 39L34.6533 29.4681L24.5165 11V39Z"
          fill="white"
          fillOpacity="0.5"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.3394 33.2538L40 21.4351L24.5165 11L37.3394 33.2538Z"
          fill="white"
          fillOpacity="0.75"
        />
        <defs>
          <filter
            id="filter0_d_546_53058"
            x="0"
            y="0"
            width="53"
            height="53"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="2" dy="2" />
            <feGaussianBlur stdDeviation="0.5" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.18 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_546_53058" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_546_53058"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    );

  return (
    <svg
      width={width}
      height={height}
      data-testid={dataTestId}
      viewBox="0 0 102 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{LOGO_LABEL}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32 35.6232L35.2547 50.1337L50.9844 22.8115L32 35.6232Z"
        fill="white"
        fillOpacity="0.75"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.5942 45.5025L50.9843 57.1887L63.3626 45.5025L50.9843 22.8115L38.5942 45.5025Z"
        fill="white"
        fillOpacity="0.3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50.9844 57.1887L63.3868 45.4858L50.9844 22.8115V57.1887Z"
        fill="white"
        fillOpacity="0.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M66.6731 50.1337L69.9285 35.6232L50.9844 22.8115L66.6731 50.1337Z"
        fill="white"
        fillOpacity="0.75"
      />
    </svg>
  );
};

export default memo(ScoodleLogo);
