const IS_DEV = process.env.NODE_ENV === "development";
export const SAFE_TARGET_ORIGINS = IS_DEV
  ? ["*"]
  : ["https://scoodleplay.acc.plantyn.com", "https://scoodleplay.plantyn.com/"];

const CLOSE_PEP_IFRAME_MESSAGE_TYPE = "close_pep_iframe";
export const INIT_MESSAGE_TYPE = "initialize_pep";
export const REFRESH_TOKEN_MESSAGE_TYPE = "pep_refresh_token";

export const closePepIframe = () => {
  window.top?.postMessage(JSON.stringify({ eventName: CLOSE_PEP_IFRAME_MESSAGE_TYPE }), "*");
};
