import {
  getTheme,
  pePepBeTokens,
  pePepNlTokens,
  pePepSeTokens,
} from "@infinitaslearning/pixel-design-system";
import type { OpCo } from "@utils/get-opco-with-locale";

export const getPixelBrandTheme = (opCo: OpCo) => {
  if (opCo === "plantyn") return getTheme(pePepBeTokens);
  if (opCo === "liber") return getTheme(pePepSeTokens);
  return getTheme(pePepNlTokens);
};
