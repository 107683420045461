import type { OpCoComponents } from "@utils/brand-components";
import { isPlatformBrowser } from "@utils/common";

export type OpCo = "noordhoff" | "plantyn" | "liber";

export type SupportedLocale = "nl-be" | "fr-be" | "nl" | "sv" | "en";
export type AnalyticsLocale = "nl-BE" | "fr-BE" | "nl-NL" | "en-US" | "sv-SE";

type OpCoWithLocale = {
  opCo: OpCo;
  locale: SupportedLocale;
  analyticsLocale: AnalyticsLocale;
};

export type OpCoInfo = OpCoWithLocale & {
  components: OpCoComponents;
};

const parseLocale = (
  locale: string | undefined,
  defaultLocale: SupportedLocale,
): SupportedLocale => {
  switch (locale) {
    case "nl-nl":
      return "nl";
    case "nl":
      return "nl";
    case "en-us":
      return "en";
    case "en":
      return "en";
    case "sv-se":
      return "sv";
    case "sv":
      return "sv";
    case "nl-be":
      return "nl-be";
    case "fr-be":
      return "fr-be";
    default:
      return defaultLocale;
  }
};

const parseLocaleForAnalytics = (locale: string | undefined, defaultLocale: AnalyticsLocale) => {
  switch (locale) {
    case "nl-nl" || "nl":
      return "nl-NL";
    case "en-us" || "en":
      return "en-US";
    case "sv-se" || "sv":
      return "sv-SE";
    case "nl-be":
      return "nl-BE";
    case "fr-be":
      return "fr-BE";
    default:
      return defaultLocale;
  }
};

export const getOpCoWithLocale = ({
  hostname,
  locale,
}: { hostname?: string; locale?: string } = {}): OpCoWithLocale => {
  // if we are in development we use a .env variable to override the OpCo
  if (process.env.NODE_ENV === "development" && process.env.NEXT_PUBLIC_OVERRIDE_OPCO) {
    const opCo = process.env.NEXT_PUBLIC_OVERRIDE_OPCO as OpCo;
    return {
      opCo: opCo,
      locale: parseLocale(locale, "en"),
      analyticsLocale: parseLocaleForAnalytics(locale, "en-US"),
    };
  }

  let host: string | undefined = undefined;
  if (hostname) {
    host = hostname;
  } else if (isPlatformBrowser()) {
    host = window.location.hostname;
  }

  const DEFAULT_OPCO_WITH_LOCALE: OpCoWithLocale = {
    opCo: "noordhoff",
    locale: parseLocale(locale, "nl"),
    analyticsLocale: parseLocaleForAnalytics(locale, "nl-NL"),
  };

  if (!host) return DEFAULT_OPCO_WITH_LOCALE;

  if (host.includes("leerkracht.juniorcloud") || host.includes("leerling.juniorcloud"))
    return {
      opCo: "noordhoff",
      locale: parseLocale(locale, "nl"),
      analyticsLocale: parseLocaleForAnalytics(locale, "nl-NL"),
    };

  if (host.includes("leerkracht.scoodle") || host.includes("leerling.plantyn"))
    return {
      opCo: "plantyn",
      locale: parseLocale(locale, "nl-be"),
      analyticsLocale: parseLocaleForAnalytics(locale, "nl-BE"),
    };

  if (host.includes("enseignant.scoodle") || host.includes("eleve.plantyn"))
    return {
      opCo: "plantyn",
      locale: parseLocale(locale, "fr-be"),
      analyticsLocale: parseLocaleForAnalytics(locale, "fr-BE"),
    };

  if (host.includes("larare.liber") || host.includes("elev.liber"))
    return {
      opCo: "liber",
      locale: parseLocale(locale, "sv"),
      analyticsLocale: parseLocaleForAnalytics(locale, "sv-SE"),
    };

  return DEFAULT_OPCO_WITH_LOCALE;
};
