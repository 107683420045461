import JuniorCloudLogo from "@components/logos/jc-logo";
import LiberLogo from "@components/logos/liber-logo";
import ScoodleLogo from "@components/logos/scoodle-logo";
import { Box } from "@infinitaslearning/pixel-design-system";
import type { OpCo } from "@utils/get-opco-with-locale";
import Image from "next/legacy/image";
import type React from "react";

export type LogoProps = {
  width?: string;
  height?: string;
  variant?: "block" | "circle" | "whiteText" | undefined;
  dataTestId?: string;
};

export type OpCoComponents = {
  Logo: React.ElementType<LogoProps>;
  LoadingImage: React.ElementType;
};

export const BRAND_COMPONENTS: Record<OpCo, OpCoComponents> = {
  noordhoff: {
    Logo: ({
      dataTestId = "brand-logo",
      height = "48px",
      variant = "block",
      width = "48px",
    }: LogoProps) => (
      <JuniorCloudLogo dataTestId={dataTestId} height={height} variant={variant} width={width} />
    ),
    LoadingImage: () => (
      <Image layout="fill" objectFit="contain" src="/images/junior-cloud-medium.png" alt="logo" />
    ),
  },
  plantyn: {
    Logo: ({
      dataTestId = "brand-logo",
      height = "48px",
      variant = "circle",
      width = "48px",
    }: LogoProps) => (
      <ScoodleLogo dataTestId={dataTestId} height={height} variant={variant} width={width} />
    ),

    LoadingImage: () => (
      <Box width="100%" height="100%" display="flex" justifyContent="center" alignItems="center">
        <Image src="/images/loading_scoodle.gif" alt="logo" width={64} height={64} />
      </Box>
    ),
  },
  liber: {
    Logo: ({ dataTestId = "brand-logo", height = "48px", variant, width = "48px" }: LogoProps) => (
      <LiberLogo dataTestId={dataTestId} height={height} variant={variant} width={width} />
    ),
    LoadingImage: () => <Image layout="fill" objectFit="contain" src="/logo512.png" alt="logo" />,
  },
};
