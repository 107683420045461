import type { LogoProps } from "@utils/brand-components";
import { type FC, memo } from "react";

// We should not use localization because language can change but is not necessarily based on opCo
const LOGO_LABEL = "Liber";

const LiberLogo: FC<LogoProps> = ({ variant, width, height, dataTestId }) => {
  if (variant === "circle")
    return (
      <svg
        width={width}
        height={height}
        data-testid={dataTestId}
        viewBox="0 0 53 53"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>{LOGO_LABEL}</title>
        <g filter="url(#filter0_d_546_53056)">
          <circle cx="25" cy="25" r="25" fill="white" />
          <circle cx="25" cy="25" r="24.75" stroke="black" strokeOpacity="0.1" strokeWidth="0.5" />
        </g>
        <path d="M21.8727 33.8535H27.7636V39.7803H21.8727V33.8535Z" fill="#00AB81" />
        <path d="M13 24.8535H21.8727V33.7803H13V24.8535Z" fill="#0085CA" />
        <path d="M21.8727 10H36.709V24.9268H21.8727V10Z" fill="#FFC71C" />
        <path d="M27.8363 30.8535H30.8181V33.8535H27.8363V30.8535Z" fill="#F61B7D" />
        <path d="M24.8545 27.8535H27.8363V30.8535H24.8545V27.8535Z" fill="#F61B7D" />
        <defs>
          <filter
            id="filter0_d_546_53056"
            x="0"
            y="0"
            width="53"
            height="53"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="2" dy="2" />
            <feGaussianBlur stdDeviation="0.5" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.18 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_546_53056" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_546_53056"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    );

  return (
    <svg
      width="158"
      height="60"
      viewBox="0 10 158 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{LOGO_LABEL}</title>
      <g clipPath="url(#clip0_543_53950)">
        <path d="M44.2 52.1001H52.3V60.2001H44.2V52.1001Z" fill="#00AB81" />
        <path d="M32 39.7998H44.2V51.9998H32V39.7998Z" fill="#0085CA" />
        <path d="M44.2 19.5H64.5999V39.9H44.2V19.5Z" fill="#FFC71C" />
        <path d="M52.3999 48H56.4999V52.1H52.3999V48Z" fill="#F61B7D" />
        <path d="M48.3 43.8999H52.4V47.9999H48.3V43.8999Z" fill="#F61B7D" />
      </g>

      <defs>
        <clipPath id="clip0_543_53950">
          <rect width="33" height="41" fill="white" transform="translate(32 19.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default memo(LiberLogo);
